import { createRouter,createWebHashHistory } from 'vue-router';

import HelloWord from '@/components/HelloWorld.vue';
import ServiceComponents from '@/components/ServiceComponents.vue';
import LeftMenu from '@/components/LeftMenu.vue';
import NumberKeyboard from '@/components/NumberKeyboard.vue';

import HomePage from '@/pages/HomePage.vue';
import DownloadPage from '@/pages/DownloadPage.vue';
import LanguagesPage from '@/pages/LanguagesPage.vue';
import NoticeRecordPage from '@/pages/NoticeRecordPage.vue';
import InviteFriendsPage from '@/pages/InviteFriendsPage.vue';
import AccountPage from '@/pages/AccountPage.vue';
import WalletPage from '@/pages/WalletPage.vue';
import TradingRecord from '@/pages/TradingRecord.vue';
import ArbitragePage from '@/pages/ArbitragePage.vue';
import TrusteeshipDetailPage from '@/pages/TrusteeshipDetailPage.vue';
import CoinDetail from '@/pages/CoinDetail.vue';
import ContractsOrderPage from '@/pages/ContractsOrderPage.vue';
import MiningPage from '@/pages/MiningPage.vue';
import SettingPage from '@/pages/SettingPage.vue';
import {getServiceUrl, getUserInfo} from "@/assets/js/api/interface";


const router = createRouter({
    history:createWebHashHistory(),
    routes:[
        {
            path:"/",
            component:HelloWord,
            name:"hello"
        },
        {
            path:"/service",
            component:ServiceComponents
        },
        {
            path:"/home",
            name:"home",
            component:HomePage
        },
        {
            path:"/download",
            component:DownloadPage
        },
        {
            path:"/languages",
            component:LanguagesPage
        },
        {
            path:"/notice",
            component:NoticeRecordPage
        },
        {
            path:"/left",
            component:LeftMenu
        },
        {
            path:"/invite_friends",
            component:InviteFriendsPage
        },
        {
            path:"/account",
            component:AccountPage
        },
        {
            path:"/wallet/:id?",
            name:"wallet",
            component:WalletPage
        },
        {
            path:"/number_keyboard/:id",
            component:NumberKeyboard,
            props: true
        },
        {
            path:"/trading_record/:id",
            name:"trading_record",
            component:TradingRecord,
            props: true
        },
        {
            path:"/arbitrage",
            component:ArbitragePage
        },
        {
            path:"/trusteeship_detail",
            component:TrusteeshipDetailPage
        },
        {
            path:"/coin_detail/:coin?",
            name:"coin_detail",
            component:CoinDetail
        },
        {
            path:'/contracts/order/:coin?',
            name:"contracts_orders",
            component:ContractsOrderPage
        },
        {
            path:'/mining',
            component:MiningPage
        },
        {
            path:'/setting',
            component:SettingPage
        },
        {
            path:"/arbitrage/ArbitageDetail/:id",
            name:"ArbitageDetail",
            component: () => import("@/pages/Arbitage/ArbitageDetail.vue")
        },
        {
            path:'/notice/detail/:id?',
            name:"notice_detail",
            component: () => import("@/pages/NoticeDetail.vue")
        },
        {
            path:'/identityAuthBefore',
            component: () => import("@/pages/IdentityAuthBefore.vue")
        },
        {
            path:'/toIdentityAuth',
            component: () => import("@/pages/IdentityAuth.vue")
        },
    ]
})

//路由过滤
router.beforeEach(async (to,from,next)=>{
    var connectionStatus = localStorage.getItem("connectionStatus");
    if (connectionStatus==null || connectionStatus==='fail'){
        getUserCode(to);
        if (to.fullPath === '/' || to.fullPath === '/download'){
            next();
        }else {
            next("/");
        }
    }else {
        try{
            const token = localStorage.getItem("token");
            if(token){
                const data = await getUserInfo(); //用户信息
                to.meta.fetchedData = data.data;
            }
            getUserCode(to);
        }catch(e){
            console.log(e)
        }finally{
            next();
        }  
    }
})

function getUserCode(to){
    const userCodes = window.location.href.split("?");
    if(userCodes.length>1){
        to.meta.userCode = userCodes[1];
    }else {
        to.meta.userCode = 'no';
    }
}

export default router;
import { ref } from 'vue';

const accounts = ref(null);

export async function initWeb3() {
    if (window.ethereum) {
        try {
            // Request account access if needed
            await window.ethereum.request({ method: "eth_requestAccounts" });//登录弹出

            // web3.value = new Web3(window.web3.currentProvider);
            // await web3.value.eth.getAccounts();
            accounts.value = await window.ethereum.enable()

            // Listen for account changes
            window.ethereum.on('accountsChanged', (accounts) => {
                accounts.value = accounts[0];
                console.log("account.value2--"+accounts.value)
            });

            // Listen for network changes
            window.ethereum.on('chainChanged', () => {
                window.location.reload();
            });
        }catch (error) {
            console.error(error)
        }
    }else{
        console.log("请安装钱包")
    }
}

export function getAccount() {
    return accounts.value;
}
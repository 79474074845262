<template>
  <div style="height: 100%;overflow: auto">
    <div>
      <div class="recharge extraBottom">

        <div class="pages_header">
          <img src="@/assets/imgs/back.png" alt="" class="back" @click="router.go(-1)" />
          <span class="App_header_title"> {{walletInfo.currency_symbol}} {{ $t('walletPage.钱包') }} </span>
          <img src="@/assets/imgs/TransactionRecord.png" alt="" class="App_icon_my" @click="router.push({ name: 'trading_record', params: { id: walletInfo.currency_id } })"/>
        </div>

        <div class="amount">
          <div class="coin_num">
            <div style="height: 70px">
              <img :src="walletInfo.currency_logo" alt="" class="coin_icon" />
            </div>
            <div class="t1">
              <span class="fs14" style="color:white"> {{ $t('walletPage.可用') }}: </span>
              <span style="color:#ffb800">{{ parseFloat(walletInfo.legal_balance) }} {{walletInfo.currency_symbol}}</span>
              <!-- <div v-if="walletInfo.currency_symbol!=='USDT'" class="fs14" style="color:#ffb800"> ≈ $ {{ parseFloat(walletInfo.currency_rate)*parseFloat(walletInfo.legal_balance) }} </div> -->
              <div v-if="walletInfo.currency_symbol !== 'USDT'" class="fs14" style="color:#ffb800"> ≈ $ {{ parseFloat(walletInfo.currency_rate) * parseFloat(walletInfo.legal_balance) }} </div>
              <div class="fs14 m-t-5">
                <span style="color:#79a3cb"> {{ $t('walletPage.冷冻') }}: {{ parseFloat(walletInfo.legal_lock_balance) }} {{walletInfo.currency_symbol}} </span>
              </div>
            </div>
          </div>
        </div>

        <div class="switch_container">
          <div class="switch_content">
            <div class="switch_item" :class="TabTel == 'receive' ? 'active' : ''"  @click="switchToggle('receive')">
                <span>{{ $t('walletPage.接收') }}</span>
            </div>

            <div class="switch_item" :class="TabTel == 'send' ? 'active' : ''" @click="switchToggle('send')">
                <span>{{ $t('walletPage.发送') }}</span>
            </div>

            <div class="switch_item" :class="TabTel == 'convert' ? 'active' : ''" @click="switchToggle('convert')">
                <span>{{ $t('walletPage.转换') }}</span>
            </div>
          </div>
        </div>

        <div class="main_container">
            <!-- 接收 -->
          <div v-if="TabTel == 'receive'" class="main_content" style="padding-bottom: 42px">
            <div class="title title-recharge">
              <div class="left"><span>{{ $t('walletPage.存入资金') }}</span></div>
              <div class="right"></div>
            </div>
            <div>
              <div class="light-tab-container" style="margin-top: 10px">
                <div class="light-tab-content">
                  <div class="light-tab-item active">{{walletInfo.currency_symbol}}</div>
                </div>
              </div>
              <div class="qr_content">
                <qrcode-vue :value="walletInfo.address" class="qr_code" />
                <div class="qr_code_value">
                  <div class="code-box" @click="selectAddress">{{chinaAddress}}</div>
                  <img src="@/assets/imgs/copy.png" alt="" @click="copyAddress" />
                </div>

                <van-field
                    v-model="rechargeAmount"
                    :name="t('接收金额')"
                    :label="t('接收金额')"
                    type="number"
                    placeholder="0.00"
                    :rules="[{ required: true, message: t('请填写金额') }]"
                    class="jieshoujine"
                />
                <div style="display: flex;flex-direction: column;align-items: flex-start">
                  <span style="color: white;margin-bottom: 10px;margin-top: 20px">{{ $t('上传充值凭证') }}:</span>
                  <van-uploader v-model="fileList" :max-count="1" :after-read="handleAfterRead" @delete="ImgUrl.value = ''"/>
                </div>

              </div>
            </div>
            <div class="send_action" @click="recharge">{{ $t('充值') }}</div>
          </div>
          <!-- 发送 -->
          <div v-if="TabTel == 'send'" class="main_content">
            <div class="flex justify-between">
              <div class="title"><span>{{ $t('walletPage.退出') }}</span></div>
            </div>
            <div class="coin_type">
              <div class="coin_item active"> {{ walletInfo.currency_symbol }} </div>
            </div>
            <div class="input_content">
              <div class="address">
                <input v-model="addressInput" type="text" placeholder="Receiving Address" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" class="address_input">
                <img src="@/assets/imgs/dustbin.png" class="icon_delete" @click="clearAddressInput">
              </div>
              <div class="address">
                <img :src="walletInfo.currency_logo" class="coin_icon">
                <input v-model="SendAmount" type="text" placeholder="Amount" readonly="readonly" class="send-am-input" @click="showNumKeyboardCom('send')" >
                <span class="coin_sympol"> {{ walletInfo.currency_symbol }} <span class="all" @click="maxWithdraw"> | {{ $t('walletPage.最大') }} </span></span>
              </div>
            </div>
            <div class="send_action" @click="withdraw">{{ $t('walletPage.立即发送') }}</div>
          </div>
          <!-- 转换 -->
          <div v-if="TabTel == 'convert'" class="main_content">
            <div class="title">
                <span>{{ $t('walletPage.转换') }}</span>
            </div>
            <div class="convert input_content">
              <div class="convert-top" style="border-bottom: 1px solid rgb(24,81,141)">
                <div class="left-info flex" style="color: rgb(121,163,203)">
                  <div class="cv-top" style="font-size: 14px;"> {{ $t('walletPage.从') }} </div>
                  <div class="cv-bottom"><span style="font-size: 14px;">({{walletInfo.currency_symbol}})</span></div>
                </div>
                <div class="right-info flex" style="margin-top: 10px">
                  <input v-model="convertInput" type="text" placeholder="0.00" readonly="readonly" class="convert-input" @click="showNumKeyboardCom('convert')"
                         style="background: transparent; text-align: left;" >
                  <img src="@/assets/imgs/dustbin.png" @click="clearAddressInput" style="width: 21px;height: 21px;margin-top: 6px">
                  <span class="all" @click="maxWithdraw">{{ $t('walletPage.最大') }}</span>
                </div>
              </div>
              <div class="convert-bottom">
                <div v-if="walletInfo.currency_symbol==='USDT'" class="left-info flex align-start justify-start" @click="showCoinPicker=true">
                  <div class="cv-top" style="color: rgb(121, 163, 203); font-size: 14px;"> {{ $t('walletPage.到') }} </div>
                  <div class="cv-bottom flex align-center justify-between">
                    <span style="color: white; font-size: 14px;">({{ coinValue }})</span>
                  </div>
                </div>
                <div v-else class="left-info flex align-start justify-start">
                  <div class="cv-top" style="color: rgb(121, 163, 203); font-size: 14px;"> {{ $t('walletPage.到') }} </div>
                  <div class="cv-bottom flex align-center justify-between">
                    <span style="color: rgb(121, 163, 203); font-size: 14px;">(USDT)</span>
                  </div>
                </div>
                <div class="right-info flex">
                  <input v-model="usdtAu" type="text" placeholder="0.00" readonly="readonly" class="convert-input2" style="background: transparent; color: rgb(255, 255, 255);" @click="showNumKeyboardCom('convert2')">
                </div>
              </div>
              <div class="fee m-t-10" style="color: rgb(121, 163, 203);">
                <span class="m-r-10">{{ $t('walletPage.费用') }}:</span>
                <span>{{walletInfo.fee_rate}}%</span>
              </div>
              <div class="send_action" @click="convertCoin"> {{ $t('walletPage.继续固定') }} </div>
            </div>
          </div>

          <div class="tips">
            <div class="tips_content">
              <div v-if="TabTel == 'receive'">{{ $t('walletPage.内容1') }}</div>
              <div v-if="TabTel == 'send'" style="text-align: center;">{{ $t('walletPage.内容2') }}</div>
              <div v-if="TabTel == 'convert'">{{ $t('walletPage.内容3') }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ServiceComponents/>
  <NumberKeyboard
      :showNumKeyboard="showNumKeyboard" :inputType="inputType" :currencyRate="currencyRate"
      @update:showNumKeyboard="showNumKeyboard = $event" @updata:CountNumber="changeNumber" @delete:CountNumber="DeleteNumber"/>

  <van-dialog v-model:show="showDialog" :title="$t('walletPage.资金密码')" show-cancel-button class="dialog"
              @confirm="handleConfirm" :cancel-button-text="$t('settingPage.取消')" :confirm-button-text="$t('settingPage.确认')" @cancel="withdrawPwd = ''">
    <div class="inputDiv">
      <van-field v-model="withdrawPwd" type="password" :label="$t('walletPage.资金密码')" :placeholder="$t('walletPage.请输入资金密码')" />
    </div>
  </van-dialog>

  <van-popup v-model:show="showCoinPicker" position="bottom">
    <van-picker :columns="coinColumns" @cancel="showCoinPicker = false" @confirm="onConfirmTime"/>
  </van-popup>

  <van-popup v-model:show="showChinaPicker" position="bottom">
    <van-picker :columns="chinaColumns" @cancel="showChinaPicker = false" @confirm="onConfirmChina"/>
  </van-popup>
</template>

<script setup>
import NumberKeyboard from '@/components/NumberKeyboard.vue';
import ServiceComponents from '@/components/ServiceComponents.vue';
import '@/assets/css/wallet.css';
import { useRouter,useRoute } from "vue-router";
import { computed, onMounted, ref, watch} from "vue";
import useClipboard from 'vue-clipboard3';
import { refresh } from "@/common/way";
import {
  accountRecharge,
  chinaInfo,
  getWalletList,
  toConvertApi,
  toWithdrawApi,
  uploadFile
} from "@/assets/js/api/interface";
import QrcodeVue from 'qrcode.vue';
import {showNotify} from "vant";
import { useI18n } from "vue-i18n";

const showCoinPicker = ref(false);
const showChinaPicker = ref(false);
const { toClipboard } = useClipboard();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const walletInfo = ref({});

// 转换前货币
const convertInput = ref();

// 转换USDT货币
const usdtAu = ref()

// 避免浮点循环更新
let isUpdating = false;
const Rate = ref();

onMounted(async () => {
    getWalletData();
    getCoinColumns();
    refresh();
})

//获取用户信息
let userInfo = route.meta.fetchedData;
const rechargeAmount = ref();                       //接收金额
const addressInput = ref();                         //发送地址
const SendAmount = ref();                           //发送金额
const chinaAddress = ref();                         //钱包地址
const chinaColumns = ref([]);     
const coinColumns = ref([])          
const fileList = ref([]);                           //充值图片
const showDialog = ref(false);
const submitType = ref()                            //提交状态
const coinValue = ref();
//tab切换
const TabTel = ref('convert');
function switchToggle(str){
    TabTel.value = str;
}
const from_symbol = ref();
const to_symbol = ref();

//获取钱包信息
let id = route.params.id;
const getWalletData = async () => {
    try {
        const data = await getWalletList(id);
        walletInfo.value = data.data
        
        if (walletInfo.value.currency_symbol == 'USDT'){
            from_symbol.value = 'USDT';
        }else{
            from_symbol.value = walletInfo.value.currency_symbol;
            to_symbol.value = 'USDT'
            Rate.value = data.data.currency_rate;
            coinValue.value = data.data.currency_symbol;
        }

        //获取链信息
        const chinaData = await chinaInfo(walletInfo.value.currency_symbol)
        chinaAddress.value = chinaData.data.list[0].address;
        for (let listElement of chinaData.data.list) {
            chinaColumns.value.push({ text: listElement.blockchain_name, value: listElement.address })
        }
    } catch (error) {
        console.error(error)
    }
}

const getCoinColumns = async () => {
    try {
        const result = await getWalletList();
        for (let item of result.data) {
            if (item.currency_symbol === 'USDT') {
                continue;
            }
            coinColumns.value.push({ text: item.currency_symbol, value: item.id })
        }
        coinValue.value = coinColumns.value[0].text
        const data = await getWalletList(coinColumns.value[0].value);
        Rate.value = data.data.currency_rate;
        console.log(result)
        console.log(from_symbol.value)

        // 当from是USDT的话，to是货币列表第一位
        if(from_symbol.value == 'USDT'){
            to_symbol.value = coinColumns.value[0].text;
        }

    } catch (error) {
        console.error(error)
    }
}

const ImgUrl = ref();
// 上传接收图片
async function handleAfterRead(file) {
    const formData = new FormData();
    formData.append('file', file.file);
    const result = await uploadFile(formData);
    ImgUrl.value = result.data.list;
}

//充值提交
const recharge = async () => {
    if (!rechargeAmount.value){
        backMsg('请输入金额')
        return
    }

    if(!ImgUrl.value){
        backMsg('请上传图片')
        return
    }

    const data = {
        symbol: walletInfo.value.currency_symbol,
        amount: parseFloat(rechargeAmount.value),
        imgs: ImgUrl.value
    }

    try{
        const result = await accountRecharge(data);
        backMsgSuccess(result.data.msg)
    } catch (error) {
        console.error(error)
    } finally {
        rechargeAmount.value = '';
        fileList.value = []
        ImgUrl.value = ''
    }
}

// 提现
function withdraw() {
    if (!addressInput.value) {
        backMsg('发送地址不能为空');
        return;
    }
    if (!SendAmount.value) {
        backMsg('金额必须大于0');
        return;
    }
    if (userInfo.has_safe_password === 0) {
        backMsg('未设置资金密码');
    } else {
        showDialog.value = true;
        submitType.value = 'wit'
    }
}

const withdrawPwd = ref();                          //发送密码
// 确认事件
const handleConfirm = async() => {
    if (!withdrawPwd.value){
        showNotify({
            message: t('settingPage.密码不能为空'),
            color: '#fff',
            background: 'red',
        });
        return;
    }
    // 发送提交
    if(submitType.value === 'wit'){
        const data = {
            'symbol': walletInfo.value.currency_symbol,
            'amount': SendAmount.value,
            'address': addressInput.value,
            'mpwd': withdrawPwd.value
        }
        try {
            const result = await toWithdrawApi(data);
            backMsgSuccess(result.data.msg)
        } catch (error) {
            console.error(error)
        }
    }
    // 转换
    if (submitType.value === 'convert'){
        const data = {
            'from_symbol': from_symbol.value,
            'to_symbol': to_symbol.value,
            'amount': convertInput.value,
            'mpwd': withdrawPwd.value
        }
        try {
            const result = await toConvertApi(data);
            backMsgSuccess(result.msg)
        } catch (error) {
            console.error(error)
        }
    }

    withdrawPwd.value = '';
    convertInput.value = '';
    GetusdtAu();
    await getWalletData();
}

const showNumKeyboard = ref(false)                  //数字键盘打开状态
const inputType = ref();
// 打开数字键盘
function showNumKeyboardCom(type) {
    showNumKeyboard.value = true;
    inputType.value = type;
}

// 数字键盘输入事件
const changeNumber = async(tel) => {
    if (tel === '.' && ((inputType.value === 'send' && (SendAmount.value + '').includes('.'))
        || (inputType.value === 'convert' && (convertInput.value + '').includes('.'))
        || (inputType.value === 'usdtAu' && (usdtAu.value + '').includes('.')))) {
        return; // 如果已经有小数点，忽略额外输入
    }

    const handleValue = (value) => {
        value = (value || '0')+ '' + tel;
        const cleanedValue = value.replace(/^0+(\d)/, '$1').replace(/^0+(\.)/, '0.');
        // 去掉前导零，但保留单个“0”或者小数形式“0.x”
        return cleanedValue;
    };

    if (inputType.value === 'send') {
        SendAmount.value = handleValue(SendAmount.value);
    } else if (inputType.value === 'convert') {
        convertInput.value = handleValue(convertInput.value);
        GetusdtAu();
    } else {
        usdtAu.value = handleValue(usdtAu.value);
        GetCon();
    }
}

// 键盘输入删除事件
const DeleteNumber = () => {
    if (inputType.value === 'send') {
        SendAmount.value = removeLastDecimal(SendAmount.value)
    } else if (inputType.value === 'convert') {
        convertInput.value = removeLastDecimal(convertInput.value)
        GetusdtAu();
    } else {
        usdtAu.value = removeLastDecimal(usdtAu.value)
        GetCon();
    }
}

const GetusdtAu = () => {
    if (from_symbol.value == 'USDT') {
        if (Number(Rate.value) == 0){
            usdtAu.value = Number(convertInput.value) || '';
            return
        }
        usdtAu.value = (Number(convertInput.value) / Number(Rate.value)) || ''
    } else {
        usdtAu.value = (Number(convertInput.value) * Number(Rate.value)) || ''
    }
}

const GetCon = () => {
    if (from_symbol.value == 'USDT') {
        if (Number(Rate.value) == 0) {
            convertInput.value = Number(usdtAu.value) || '';
            return
        }
        convertInput.value = (Number(usdtAu.value) / Number(Rate.value)) || ''
    } else {
        convertInput.value = (Number(usdtAu.value) * Number(Rate.value)) || ''
    }
}

const removeLastDecimal = (item) => {
    if(item){
        return (item + '').slice(0, -1)
    }
}

const maxWithdraw = () => {
    if (TabTel.value == 'send') {
        SendAmount.value = walletInfo.value.legal_balance;
    } else {
        convertInput.value = parseFloat(walletInfo.value.currency_rate) * parseFloat(walletInfo.value.legal_balance);
        GetusdtAu();
    }
}

const clearAddressInput = () => {
    if (TabTel.value == 'send'){
        addressInput.value = '';
    }else{
        convertInput.value = '';
        GetusdtAu();
    }
}

const onConfirmTime = async(item) => {
    const data = await getWalletList(item.selectedOptions[0].value);
    coinValue.value = data.data.currency_symbol;
    Rate.value = data.data.currency_rate;
    walletInfo.value.fee_rate = data.data.fee_rate;
    GetusdtAu();
    to_symbol.value = data.data.currency_symbol;
    showCoinPicker.value = false;
}

// 换转提交事件
const convertCoin = () => {
    if (!convertInput.value){
        backMsg('金额必须大于0');
        return;
    }

    if (userInfo.has_safe_password === 0){
        backMsg('未设置资金密码');
        return
    }

    showDialog.value = true;
    submitType.value = 'convert';
}

function backMsg(msg) {
    showNotify({
        message: t('walletPage.' + msg),
        color: '#fff',
        background: 'red',
    });
}

function backMsgSuccess(msg) {
    showNotify({
        message: msg,
        color: '#fff',
        background: 'green',
    });
}
</script>

<style scoped>
.num_keyboard{
  padding-bottom: 0;
}
.num_keyboard ::v-deep .van-number-keyboard__body{
  background: #023469 !important;
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key{
  background: #5789bd;
  color: #fff;
  font-family: Poppins,sans-serif;
  font-size: 5.33333vw;
  font-weight: 500;
  line-height: 6.4vw;
  letter-spacing: 0;
  text-align: center;
}
.num_keyboard ::v-deep .van-number-keyboard__body .van-key__wrapper .van-key--active{
  background-color: rgba(87,137,189,0.7) !important; /* 修改按钮点击效果颜色 */
}
</style>